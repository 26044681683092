import keys from "./keys";


export function getSessionData(key){
    if(key){
        return localStorage.getItem(key);
    }
    return localStorage.getItem(keys.SESSION_KEY) || null ;
}

export function setSessionData(data,key){
    if(key){
        return localStorage.setItem(key,data);
    }
    return localStorage.setItem(keys.SESSION_KEY,data);
}

export function clearSessionData (key) {
    if (key) {
      return localStorage.setItem(key, null)
    }
    return localStorage.setItem(keys.SESSION_KEY, {});
  }