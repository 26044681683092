let timeout = null

function debounce(func,wait = 300,immediate = false){
	// 清除定时器
	if(timeout !== null)clearTimeout(timeout);
	
	if(immediate){
		// 立即执行
		var callNow = !timeout;
		timeout = setTimeout(function(){
			timeout = null;
		},wait);
		if(callNow)typeof func === 'function' && func();
	}else{
		timeout = setTimeout(function(){
			typeof func === 'function' && func();
		},wait);
	}
	
}

export default debounce