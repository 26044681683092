<template>
  <div>
    <top></top>
    <div class="common_box">
      <div class="top_box" style="padding-bottom: 30px">
        <div class="top_news" @click="navigateToArticleDetail(topNews)">
          <h3>{{ topNews ? topNews.tit : "" }}</h3>
          <div class="top_brief">
            <span
              class="brief_con"
              v-html="topNews ? topNews.content : ''"
              style="height: 20px"
            >
            </span>
            <span>查看详情 》</span>
          </div>
        </div>
        <div class="top_banner">
          <swiper
            :modules="modules"
            :slides-per-view="1"
            :space-between="50"
            :autoplay="{
              delay: 5000,
              disableOnInteraction: false,
            }"
            :loop="true"
            @slideChangeTransitionEnd="onSlideChange"
            :activeIndex="currentIndex"
            :navigation="true"
            class="banner_box"
            ref="swipper"
          >
            <swiper-slide v-for="(item, index) in arr" :key="index">
              <img :src="item.imgsrc" @click.self="handleOpen(item)" />
            </swiper-slide>
            <!-- <div class="swiper-button-prev" @click="handlePrev"></div>
            <div class="swiper-button-next" @click="handleNext"></div> -->
            <div class="banner_tit">
              <span class="banner_tit_text">{{
                arr[currentIndex] ? arr[currentIndex].title : ""
              }}</span>
              <div class="banner_num">
                <span
                  :class="[
                    'num_text',
                    currentIndex == index ? 'num_active' : '',
                  ]"
                  v-for="(item, index) in arr"
                  :key="index"
                  >{{ index + 1 }}</span
                >
              </div>
            </div>
          </swiper>
          <div class="banner_text">
            <div class="news_tab">
              <ul class="tab_box" style="min-height: 40px">
                <li
                  v-for="(item, index) in tabList"
                  :key="index"
                  class="tab_list"
                  @click="changes(index)"
                  :class="{ tab_active: index == num }"
                >
                  {{ item.name }}
                </li>
              </ul>
              <div
                class="tab_more"
                style="cursor: pointer"
                @click="
                  $router.push({
                    name: 'About',
                    query: { cate_id: tabList[num].id },
                  })
                "
              >
                <span>更多</span>
                <img src="../assets/icon_arrow1.png" />
              </div>
            </div>
            <ul class="news_box">
              <li
                v-for="(item, index) in newsList"
                :key="index"
                class="news_list"
                @click="navigateToArticleDetail(item)"
              >
                <span class="news_round"></span>
                <div class="news_con">{{ item.tit }}</div>
                <span class="news_time">{{ item.time }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <!--地方馆-->
        <place :category="recommendCate"></place>
        <!--商品列表-->
        <div
          class="recommend_box"
          style="padding-top: 20px"
          v-for="item in products"
          :key="item.id"
        >
          <div class="recommend_tit" style="padding-bottom: 0">
            <span class="recommend_line"></span>
            <span>{{ item.name }}</span>
          </div>
          <goods :goodsList="item.son_cate"></goods>
        </div>
      </div>
    </div>
    <bottom></bottom>
    <right></right>
  </div>
</template>

<script>
// @ is an alias to /src

import Top from "../components/Top";
import Bottom from "../components/Bottom";
import Right from "../components/Right";
// 2.导入轮播组件 import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import "swiper/swiper-bundle.min.css";
import Goods from "../components/Goods";
import Place from "../components/Place";
import {
  getClass,
  getGood,
  getIndexPage,
  getGoodsCateList,
  getGoodsCateListTwo,
} from "@/api/shop.js";
import { getArticleCate, getArticleList } from "@/api/article.js";
import { getBannerList } from "@/api/banner.js";
import dayjs from "dayjs";
export default {
  name: "Home",
  components: {
    Place,
    Goods,
    Right,
    Bottom,
    Top,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      arr: [],
      num: 0,
      tabList: [],
      newsList: [],
      currentIndex: 0,
      products: [],
      recommendCate: [],
      topNews: {},
    };
  },
  created() {
    this.getClass();
    this.getGood();
    this.getArticleCate();
    this.getIndexPage();
    this.getGoodsCateListTwo();
    this.getBannerList();
    this.getGoodsCateList();
    this.handleFetchTopNews();
  },
  methods: {
    handleOpen(item) {
      window.open(item.url);
    },
    getGoodsCateList() {
      getGoodsCateList({
        app_id: this.$store.state.appId,
      }).then((res) => {
        this.recommendCate = [637, 657, 648, 658, 730, 660, 700, 659].map(
          (id) => {
            return res.data.find((item) => {
              return item.id == id;
            });
          }
        );
      });
    },
    handleFetchTopNews() {
      getArticleList({
        app_id: this.$store.state.appId,
      }).then((res) => {
        console.log(res);
        this.topNews = {
          id: res.data[0].id,
          cate_id: res.data[0].cate_id,
          tit: res.data[0].title,
          content: res.data[0].content,
          time: dayjs(res.data[0].i_time * 1000).format("YYYY-MM-DD"),
        };
      });
    },
    navigateToArticleDetail(item) {
      console.log(item);
      this.$router.push({
        name: "AboutDetail",
        query: {
          cate_id: item.cate_id,
          a: item.id,
        },
      });
    },
    changes(key) {
      this.num = key;
    },
    onSlideChange(e) {
      console.log(e.realIndex);
      this.currentIndex = e.activeIndex % this.arr.length;
    },
    getClass() {
      console.log(this.$store.state.shopId);
      var params = {
        shop_id: this.$store.state.shopId,
      };
      getClass(params).then((res) => {
        console.log(res);
      });
    },
    getBannerList() {
      getBannerList({
        app_id: this.$store.state.appId,
      }).then((res) => {
        this.arr = res.data
          .filter((item) => item.show)
          .map((item) => ({ ...item, imgsrc: item.img }));
        console.log(this.arr, "-=-=");
      });
    },
    getGoodsCateListTwo() {
      getGoodsCateListTwo({
        shop_id: this.$store.state.shopId,
        app_id: this.$store.state.appId,
      }).then((res) => {
        console.log(res);
        this.products = res.data.filter((item) => item.son_cate.length);
      });
    },
    getIndexPage() {
      getIndexPage({
        // shop_id: this.$store.state.shopId,
        app_id: this.$store.state.appId,
      }).then((res) => {
        console.log(res);
      });
    },
    getGood() {
      var params = {
        shop_id: this.$store.state.shopId,
        cate_id: "",
        area_id: 0,
        group_id: 0,
        search_txt: "",
        sort_type: "all", //排序规则  all 综合 price 价格  sell_num 销量
        sort: "desc",
        page_idx: 1,
      };
      getGood(params).then((res) => {
        console.log(res);
      });
    },
    handleFetchArticle() {
      getArticleList({
        app_id: this.$store.state.appId,
        cate_id: this.tabList[this.num].id,
      }).then((res) => {
        console.log(res);
        this.newsList = res.data.map((item) => {
          return {
            id: item.id,
            cate_id: this.tabList[this.num].id,
            tit: item.title,
            content: item.content,
            time: dayjs(item.i_time * 1000).format("YYYY-MM-DD"),
          };
        });
      });
    },
    getArticleCate() {
      var params = {
        app_id: this.$store.state.appId,
        id: 0, //上级分类
      };
      getArticleCate(params).then((res) => {
        this.tabList = res.data;
        if (this.tabList.length) {
          this.handleFetchArticle();
        }
      });
    },
  },
  watch: {
    num() {
      this.handleFetchArticle();
    },
  },
  setup() {
    return {
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
};
</script>

<style lang="less">
@import "../common/public.less";

.top_box {
  .top_news {
    cursor: pointer;
    text-align: center;
    width: 1000px;
    margin: 0 auto;
    padding: 20px 0;
    h3 {
      font-size: 40px;
      font-weight: bold;
      color: #444;
      .overo;
    }
    .top_brief {
      color: #777;
      padding-top: 10px;
      .disaf;
      cursor: pointer;
      .brief_con {
        display: inline-block;
        text-overflow: ellipsis;
        display: -webkit-box;
        white-space: nowrap;
        overflow: hidden;

        width: 900px;
        .overo;
        font-size: 15px;
        img {
          display: none;
        }
        p {
          color: #666 !important;
          font-weight: 500 !important;
        }
      }
    }
  }
  .top_banner {
    .disaf;
    .banner_box {
      width: 680px;
      height: 400px;
      overflow: hidden;
      position: relative;
      --swiper-theme-color: #ff6600; /* 设置Swiper风格 */
      --swiper-navigation-color: #fff; /* 单独设置按钮颜色 */
      --swiper-navigation-size: 30px; /* 设置按钮大小 默认是44px */
      .swiper-button-prev,
      .swiper-button-next {
        background-color: rgba(0, 0, 0, 0.6);
        padding: 10px;
      }
      .banner_tit {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 3;
        background: rgba(0, 0, 0, 0.4);
        color: #fff;
        font-size: 14px;
        height: 40px;
        line-height: 40px;
        padding: 0 20px;
        width: 640px;
        .disaf;
        .banner_tit_text {
          max-width: 500px;
          display: inline-block;
          .overo;
        }
        .banner_num {
          .num_text {
            display: inline-block;
            width: 20px;
            height: 20px;
            text-align: center;
            line-height: 20px;
            background: rgba(0, 0, 0, 0.8);
            color: #fff;
            border-radius: 1px;
            margin-left: 2px;
            cursor: pointer;
          }
          .num_active {
            background: #ff4800;
          }
        }
      }
      img {
        width: 680px;
        height: 400px;
      }
    }
    .banner_text {
      margin-left: 30px;
      width: 490px;
      height: 400px;
      .news_tab {
        .disaf;
        border-bottom: 1px solid #ececec;
        .tab_box {
          max-width: 430px;
          overflow-x: auto;
          .disa;
          .tab_list {
            height: 40px;
            line-height: 40px;
            box-sizing: border-box;
            color: @color;
            margin-right: 20px;
            cursor: pointer;
          }
          .tab_active {
            font-weight: bold;
            border-bottom: 2px solid @color;
          }
        }
        .tab_more {
          font-size: 14px;
          color: #999;
          .disa;
          img {
            margin-left: 2px;
          }
        }
      }
      .news_box {
        max-height: 350px;
        overflow-y: auto;
        padding-top: 10px;
        .news_list {
          .disa;
          cursor: pointer;
          color: #666;
          padding: 7px 0;
          .news_round {
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: 2px solid #cbcbcb;
            margin-right: 10px;
          }
          .news_con {
            max-width: 380px;
            .overo;
          }
          .news_time {
            font-size: 14px;
            margin-left: auto;
          }
        }
      }
    }
  }
}
.recommend_box {
  margin: 25px 0;
}

.top_box {
  padding-bottom: 0;
  .nav_list {
    padding-bottom: 25px;
  }
}
</style>
