<template>
  <div id="nav">
<!--    <router-link to="/">Home</router-link> |-->
<!--    <router-link to="/about">About</router-link>-->
  </div>
  <router-view />
</template>

<script>
  import {
		getSessionData,
		setSessionData
	} from './utils/session';
  import {
		loginWithCode
	} from './api/user.js'
  import { ElMessage } from 'element-plus';
  import keys from '@/utils/keys.js'
export default{

    onLaunch:function(options){
      console.log(options);
      !getSessionData()?setSessionData({}) : '';
      // 判断是否带有code参数，要在这里处理
      var search = window.location.search;
      search = search.startsWith('?') ? search.slice(1):search;
      search = search.split('&');
      var  query = {};
      for( var item of search){
        console.log(item);
        var kv = item.split('=')
        query[kv[0]] = kv[1];
      }

      if(query.code){
        // 微信授权登陆
        loginWithCode(query.code).then(res=>{
          if (res.code != 1) {
            ElMessage.error(res.msg);
          }else{
            setSessionData({
              ...getSessionData(),
              userInfo:res.data.user
            })
            // 返回初始页面
            const beforeLogin = sessionStorage.getItem(keys.BEFORELOFINHREF)
            sessionStorage.removeItem(keys.BEFORELOFINHREF)
            window.location.replace(beforeLogin)


          }
        })
      }

    },

  }
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
::-webkit-scrollbar{
  width: 5px;
  height: 5px;
  background: #f5f5f5;

}
::-webkit-scrollbar-thumb{
  background: #999;
  border-radius: 100px;
}
</style>
