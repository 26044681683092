import http from '../utils/request'



/**
 * 获取轮播图
 * @param {*} params 
 * @returns 
 */
export function getBannerList(params){
  return http.get('/pc/mall?m=getBannerList',{params});
}
