import { createStore } from "vuex";

export default createStore({
  state: {
    appId:'57847356',//项目id
    shopId:'560', //店铺id
    wechatAppId:'wxb84c7465b2b793a6',//绑定的小程序Id
  },
  mutations: {},
  actions: {},
  modules: {},
});
