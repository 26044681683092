<template>
  <div>
    <top></top>
    <div class="common_box">
      <div class="nav_tit">
        <span>首页</span>
        <template v-if="detail.cate_pid">
          <img src="../assets/icon_arrow1.png" />
          <router-link
            style="color: #555"
            :to="{ name: 'productList', query: { cate_id: detail.pcate.id } }"
          >
            <span>{{ detail.pcate.name }}</span>
          </router-link>
        </template>
        <template v-if="detail.cate_id">
          <img src="../assets/icon_arrow1.png" />
          <router-link
            style="color: #555"
            :to="{
              name: 'productList',
              query: { cate_id: detail.cate?.id },
            }"
          >
            <span>{{ detail.cate?.name }}</span>
          </router-link>
        </template>
      </div>
      <div class="particulars_box">
        <amplification :image-list="detail.goods_image"></amplification>
        <div class="particulars_fr">
          <div class="particulars_tit">{{ detail.goods_title }}</div>
          <div class="particulars_info">
            {{ detail.goods_desc }}
          </div>
          <div class="particulars_core">
            <div class="core_list">
              <span class="core_label">价格</span>
              <span class="core_price">￥{{ detail.goods_min_price }}</span>
            </div>
            <div class="core_list">
              <span class="core_label">运费</span>
              <span v-if="detail.freight_type == 0"
                >￥{{ detail.freight_price }}</span
              >
              <span v-else>包邮</span>
            </div>
            <!-- <div class="core_list core_list1">
              <span class="core_label">评价</span>
              <span>{{ total || 0 }}条</span>
            </div> -->
          </div>
          <div class="specification_box">
            <span class="specification_tit">规格</span>
            <div class="specification_data">
              <span
                v-for="(item, index) in specList"
                :key="index"
                :style="{
                  cursor: 'pointer',
                  borderColor: current == index ? 'red' : '',
                  color: current == index ? 'red' : '',
                }"
                @click="current = index"
              >
                {{ item.spec_name }}
              </span>
              <!-- <span>130g/罐</span> -->
            </div>
          </div>
          <div class="specification_box">
            <span class="specification_tit">数量</span>
            <div class="number_box">
              <span class="number_operate" @click="sub">-</span>
              <input
                placeholder=""
                v-model="productNumber"
                class="number_data"
              />
              <span class="number_operate" @click="add">+</span>
            </div>
          </div>
          <button class="particulars_btn" @click="handleBuy">立即购买</button>
        </div>
      </div>
      <div class="description_box">
        <div class="description_fl">
          <ul class="description_tab">
            <li @click="changes((num = 0))" :class="{ active: num == 0 }">
              详情
            </li>
            <!-- <li @click="changes((num = 1))" :class="{ active: num == 1 }">
              评价（{{ total || 0 }}）
            </li> -->
          </ul>
          <ul class="description_synopsis">
            <li
              class="synopsis_con"
              v-if="num == 0"
              v-html="detail.goods_content"
            ></li>
            <!--评价-->
            <!-- <li v-if="num == 1">
              <ul class="evaluation_box">
                <li
                  v-for="(item, index) in evaluationList"
                  :key="index"
                  class="evaluation_list"
                >
                  <div class="evaluation_fl">
                    <img :src="item.headimg" />
                    <p>{{ item.name }}</p>
                  </div>
                  <div class="evaluation_fr">
                    <div class="evaluation_time">{{ item.specification }}</div>
                    <div>{{ item.evtext }}</div>
                    <div class="evaluation_evimg">
                      <img
                        v-for="(evimg, index) in detail.evimgList"
                        :key="index"
                        :src="evimg"
                      />
                    </div>
                    <div class="evaluation_time">{{ item.time }}</div>
                  </div>
                </li>
              </ul>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 20px;
                "
              >
                <el-pagination
                  background
                  :page-size="20"
                  layout="prev, pager, next"
                  :total="total"
                  @current-change="handleCurrentChange"
                >
                </el-pagination>
              </div>
            </li> -->
          </ul>
        </div>
        <!--热门排行榜-->
        <div class="description_fr">
          <p class="hot_tit">热门排行榜</p>
          <div class="hot_goods">
            <goods :goodsList="goodsList"></goods>
          </div>
        </div>
      </div>
    </div>
    <bottom></bottom>
    <right></right>

    <el-dialog v-model="buyVisible" title="打开微信扫一扫" width="30%" center>
      <div style="text-align: center">
        <div style="margin-bottom: 20px">请到小程序中购买</div>
        <img
          :src="shareDetail.qrcode"
          alt=""
          style="width: 200px; height: 200px"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Top from "../components/Top";
import Bottom from "../components/Bottom";
import Right from "../components/Right";
import Amplification from "../components/Amplification";
import Goods from "../components/Goods";
import {
  getGoodsDetail,
  getGoodsSpec,
  getShareInfo,
  commonLists,
} from "@/api/goods";
import { getGood } from "@/api/shop";
export default {
  name: "GoodsDetails",
  components: { Goods, Right, Bottom, Top, Amplification },
  data() {
    return {
      detail: {},
      goodsName: "",
      num: 0,
      productNumber: 1,
      buyVisible: false,
      specList: [],
      current: {},
      shareDetail: {},
      page: 1,
      total: 0,
      evaluationList: [],
      goodsList: [],
    };
  },
  created() {
    this.handleFetchGoodsDetail();
    this.handleFetchSpec();
    this.getShareInfo();
    this.commonLists();
  },
  watch: {
    current() {
      this.productNumber = 1;
    },
  },
  methods: {
    handleFetchGoodsList() {
      getGood({
        cate_id: this.detail.cate_id,
        page_idx: 1,
        page_size: 10,
      }).then((res) => {
        this.goodsList = res.data;
      });
    },
    handleCurrentChange(current) {
      this.page = current;
      this.commonLists();
    },
    commonLists() {
      commonLists({
        goods_id: this.$route.params.id,
        page_idx: this.page,
        page_size: 20,
      }).then((res) => {
        console.log(res);
        this.evaluationList = res.data.map((item) => {
          return {
            headimg: item.wx_avatar_url || require("../assets/good_img.png"),
            name: item.wx_name || "微信用户",
            specification: "130g/罐",
            evtext: item.content,
            evimgList: item.img.filter(Boolean),
            time: item.i_time,
          };
        });
        this.total = res.datas.count;
      });
    },
    getShareInfo() {
      getShareInfo({
        goods_id: this.$route.params.id,
        shop_id: this.$store.state.shopId,
        app_id: this.$store.state.appId,
        type: 0,
      }).then((res) => {
        this.shareDetail = res.data;
      });
    },
    handleBuy() {
      this.buyVisible = true;
    },
    sub() {
      if (this.productNumber > 1) {
        this.productNumber -= 1;
      }
    },
    add() {
      this.productNumber += 1;
    },
    changes(key) {
      this.num = key;
    },
    handleFetchGoodsDetail() {
      getGoodsDetail({
        id: this.$route.params.id,
        shop_id: this.$store.state.shopId,
        app_id: this.$store.state.appId,
      }).then((res) => {
        console.log(res, "======");
        this.detail = res.data;
        this.handleFetchGoodsList();
      });
    },
    handleFetchSpec() {
      getGoodsSpec({
        id: this.$route.params.id,
        shop_id: this.$store.state.shopId,
        app_id: this.$store.state.appId,
      }).then((res) => {
        console.log(res);
        this.specList = res.data.specs;
      });
    },
  },
  watch: {
    ["$route.params.id"]: function () {
      if (this.$route.params.id) {
        this.handleFetchGoodsDetail();
        this.handleFetchSpec();
        this.getShareInfo();
        this.commonLists();
      }
    },
  },
};
</script>

<style lang="less">
@import "../common/public.less";
body {
  background: #fff;
}
.nav_tit {
  display: flex;
  align-items: center;
  padding: 20px 0;
  font-size: 14px;
  color: #666;
  img {
    margin: 0 5px;
  }
}
.particulars_box {
  .disf;
  .disf;
  .particulars_fr {
    width: 700px;
    padding: 20px 0;
    .particulars_tit {
      font-size: 20px;
      font-weight: bold;
    }
    .particulars_info {
      color: #666;
      padding: 10px 0 20px;
    }
    .particulars_core {
      background: #f1f1f1;
      border-radius: 10px;
      padding: 30px 10px 0;
      .core_list {
        padding-bottom: 20px;
        .core_label {
          display: inline-block;
          width: 50px;
          color: #777;
        }
        .core_price {
          color: @color;
          font-size: 24px;
          font-weight: bold;
        }
      }
      .core_list1 {
        border-top: 1px dashed #dbdbdb;
        padding-top: 20px;
      }
    }
    .specification_box {
      .disa;
      padding: 20px 0 20px;
      color: #777;
      .specification_tit {
        display: inline-block;
        width: 60px;
      }
      .specification_data {
        max-width: 640px;
        span {
          padding: 8px 30px;
          border: 1px solid #d7d7d7;
          margin-right: 30px;
          margin-top: 20px;
          display: inline-block;
        }
      }
      .number_box {
        border: 1px solid #d7d7d7;
        height: 40px;
        line-height: 40px;
        .number_operate {
          padding: 0 20px;
          font-size: 18px;
          cursor: pointer;
          user-select: none;
        }
        .number_data {
          width: 70px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: none;
          border: none;
          border-left: 1px solid #d7d7d7;
          border-right: 1px solid #d7d7d7;
        }
      }
    }
    .particulars_btn {
      width: 500px;
      height: 60px;
      line-height: 60px;
      background: @color;
      color: #fff;
      margin: 20px 100px;
    }
  }
}
.description_box {
  height: fit-content;
  align-items: stretch;
  margin-bottom: 200px;
  .disf;
  .description_fl {
    width: 830px;
    .description_tab {
      .disa;
      height: 65px;
      background: #f5f4f4;
      border: 1px solid #dcdcdc;
      padding-left: 1px;
      li {
        cursor: pointer;
        width: 150px;
        text-align: center;
        height: 65px;
        line-height: 65px;
      }
      .active {
        background: #fff;
        border-top: 2px solid @color;
      }
    }
    .description_synopsis {
      width: 100%;
      .synopsis_con {
        // font-size: 0;
        padding-top: 25px;
        img {
          width: 100%;
        }
      }
    }
    .evaluation_box {
      padding: 0 30px;
      .evaluation_list {
        .dis;
        border-bottom: 1px dashed #ddd;
        padding: 40px 0 20px;
        &:last-child {
          border-bottom: none;
        }
        .evaluation_fl {
          width: 70px;
          text-align: center;
          img {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            margin-bottom: 10px;
          }
        }
        .evaluation_fr {
          max-width: 720px;
          margin-left: 30px;
          .evaluation_time {
            font-size: 14px;
            color: #999;
            padding-bottom: 10px;
          }
          .evaluation_evimg {
            .dis;
            padding: 20px 0 0;
            img {
              width: 120px;
              height: 120px;
              border: 1px solid #ececec;
              margin-right: 20px;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
  .description_fr {
    width: 330px;
    border: 1px solid #dcdcdc;
    background: #fff;
    .hot_tit {
      background: #f5f4f4;
      padding: 20px 30px;
      text-align: center;
      font-size: 18px;
    }
    .hot_goods {
      margin: 0 20px;
    }
  }
}
</style>
