import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Beauty from "../views/Beauty.vue";
import BeautyDetails from "../views/BeautyDetails.vue";
import LocalLibrary from "../views/LocalLibrary.vue";
import GoodsDetails from "../views/GoodsDetails.vue";
import Supplier from "../views/Supplier.vue";
import SupplierDetail from "../views/SupplierDetail.vue";
import About from "../views/About.vue";
import ProductList from "../views/ProductList.vue";
import AboutDetail from "../views/AboutDetail.vue";
import ContactUS from "../views/ContactUS.vue";




const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/goods/:id",
    name: "GoodsDetails",
    component: GoodsDetails,
  },
  {
    path: "/library",
    name: "LocalLibrary",
    component: LocalLibrary,
  },

  {
    path: "/beauty/:id",
    name: "BeautyDetails",
    component: BeautyDetails,
  },
  {
    path: "/beauty",
    name: "Beauty",
    component: Beauty,
  },
  {
    path: "/supplier",
    name: "Supplier",
    component: Supplier,
  },
  {
    path: "/supplier/detail",
    name: "SupplierDetail",
    component: SupplierDetail,
  },
  {
    path: "/productList",
    name: "productList",
    component: ProductList,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/about/detail",
    name: "AboutDetail",
    component: AboutDetail,
  },
  {
    path: "/about/contact",
    name: "ContactUS",
    component: ContactUS,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
  
});

router.beforeEach((to,from,next)=>{
  document.body.scrollTop = 0; 
  document.documentElement.scrollTop = 0; 
  window.pageYOffset = 0;
  next();
})

export default router;
