<template>
  <div>
    <top></top>
    <div style="width: 1200px; margin: 0 auto">
      <div class="nav_tit">
        <span>首页</span>
        <template v-if="currentNav">
          <img src="../assets/icon_arrow1.png" />
          <router-link style="color: #555" :to="{ name: 'ContactUS' }">
            <span>联系我们</span>
          </router-link>
        </template>
      </div>
    </div>
    <div class="common_box about_box" style="padding-top: 0">
      <ul class="about_fl">
        <!--选中加about_acitve-->
        <router-link
          :to="{ name: 'About', query: { cate_id: item.id } }"
          replace
          v-for="(item, index) in navList"
          :key="index"
          style="color: #555"
        >
          <li
            :class="['about_nav_list']"
            style="cursor: pointer"
            @click="handleClick(item)"
          >
            <span>{{ item.name }}</span>
            <!--<img src="../assets/icon_arrow.png">-->
          </li>
        </router-link>
        <router-link
          :to="{ name: 'ContactUS' }"
          replace
          :key="index"
          style="color: #555"
        >
          <li :class="['about_nav_list', 'active']" style="cursor: pointer">
            <span>联系我们</span>
            <!--<img src="../assets/icon_arrow.png">-->
          </li>
        </router-link>
      </ul>
      <div class="about_fr">
        <!--联系我们-->
        <div class="tel_box">
          <div class="tel_tit">联系我们</div>
          <div class="tel_list">
            <img src="../assets/img1.png" class="tel_img" />
            <div class="tel_con">
              <p>客服电话：0551-82626107 或 15375250205</p>
              <p>客服在线时间：8:30 - 19:00</p>
              <p>电话咨询时间：工作日早8:30到晚17:30</p>
              <!-- <p>休息日（节假日）：微信（19348234022）</p> -->
            </div>
          </div>
          <div class="tel_list">
            <div class="tel_con">
              <p>如果您想</p>
              <p>成为帮扶平台的线上销售渠道商，</p>
              <p>为终端提供高品质的严选产品及服务；</p>
              <p>请联系我们</p>
            </div>
            <img src="../assets/img2.png" class="tel_img" />
          </div>
        </div>
      </div>
    </div>
    <bottom></bottom>
    <right></right>
  </div>
</template>

<script>
import Top from "../components/Top";
import Bottom from "../components/Bottom";
import Right from "../components/Right";

import { getArticleCate, getArticleList } from "@/api/article.js";
export default {
  components: { Right, Bottom, Top },
  data() {
    return {
      navList: [
        // "政策文件","商城公告","保障服务","七天无理由","消费者保障","发货服务","系统快报","底部帮助","购物指南","店主之家","售后服务","关于我们",
      ],
      fileList: [],
      num: 0,
      currentArticle: 0,
    };
  },
  computed: {
    detail() {
      return this.fileList.find((item) => item.id == this.currentArticle) || {};
    },
    currentNav() {
      return this.navList.find((item) => item.id == this.num) || {};
    },
  },
  mounted() {
    this.num = this.$route.query.cate_id;
    this.currentArticle = this.$route.query.a;
    this.getArticleCate();
  },
  methods: {
    handleClick(item) {
      this.num = item.id;
    },
    handlePreview(item) {
      this.currentArticle = item.id;
    },
    handleFetchArticle() {
      getArticleList({
        app_id: this.$store.state.appId,
        cate_id: this.num,
      }).then((res) => {
        console.log(res);
        this.fileList = res.data;
        if (this.fileList.length) {
          if (!this.$route.query.a) {
            this.currentArticle = this.$route.query.a;
          }
          this.currentArticle = this.fileList[0].id;
        }
      });
    },
    getArticleCate() {
      var params = {
        app_id: this.$store.state.appId,
        id: 0, //上级分类
      };
      getArticleCate(params).then((res) => {
        this.navList = res.data;
        if (this.navList.length) {
          if (!this.$route.query.cate_id) {
            this.num = this.navList[0].id;
          }
          this.handleFetchArticle();
        }
      });
    },
  },
  watch: {
    num() {
      this.handleFetchArticle();
    },
  },
};
</script>

<style lang="less">
@import "../common/public.less";
body {
  background: #fff;
}
.top_box {
  border-bottom: 1px solid #ececec;
}
.active {
  color: red !important;
}
.about_box {
  .disf;
  padding: 40px 0 50px;
  font-size: 14px;
  .about_fl {
    width: 200px;
    text-align: center;
    border: 1px solid #ececec;
    .about_nav_list {
      padding: 20px 0;
      font-size: 16px;
      border-bottom: 1px solid #ececec;
      .disa;
      justify-content: center;
      &:last-child {
        border-bottom: none;
      }
      img {
        width: 15px;
        height: 15px;
        margin-left: 10px;
      }
    }
    .about_acitve {
      background: #faf6f6;
      color: @color;
    }
  }
  .about_fr {
    width: 970px;
    height: auto;
    border: 1px solid #ececec;
    .file_box {
      padding: 0 30px;
      .file_list {
        padding: 20px 0;
        border-bottom: 1px solid #ececec;
        color: #444;
        &:last-child {
          border-bottom: none;
        }
      }
    }
    .file_details {
      padding: 30px;
      .file_tit {
        font-size: 18px;
        font-weight: bold;
        color: #222;
        text-align: center;
        padding-bottom: 20px;
      }
      .file_con {
        img {
          width: 100%;
          padding-bottom: 10px;
        }
      }
    }
    .tel_box {
      padding: 0 30px;
      .tel_tit {
        padding: 20px 0;
        border-bottom: 1px solid #ececec;
      }
      .tel_list {
        .disaf;
        padding: 30px 0;
        .tel_img {
          width: 580px;
          height: 260px;
        }
        .tel_con {
          line-height: 40px;
        }
      }
    }
  }
}
</style>
