import http from '../utils/request'


/**
 * 获取文章分类
 * @param {*} params 
 * @returns 
 */
export function getArticleCate(params){
    return http.get('/pc/news?m=getNewsForCate',{params});
}


/**
 * 获取文章列表
 * @param {*} params 
 * @returns 
 */
export function getArticleList(params){
    return http.get('/pc/news?m=getNewsListAll',{params});
}


/**
 * 获取文章详情
 * @param {*} params 
 * @returns 
 */
export function getArticleInfo(params){
    return http.get('/pc/news?m=getNewsForId',{params});
}