<template>
  <div>
    <top></top>
    <div class="local_banner">
      <img src="../assets/banner1.png" />
    </div>
    <div class="local_box">
      <div class="recommend_tit">
        <span class="recommend_line"></span>
        <span>巢湖消费地方帮扶馆</span>
      </div>
      <place :category="recommendCate"></place>
    </div>
    <bottom></bottom>
    <right></right>
  </div>
</template>

<script>
import Top from "../components/Top";
import Bottom from "../components/Bottom";
import Right from "../components/Right";
import Place from "../components/Place";
import {
  getClass,
  getGood,
  getIndexPage,
  getGoodsCateList,
  getGoodsCateListTwo,
} from "@/api/shop.js";
export default {
  name: "LocalLibrary",
  components: { Place, Right, Bottom, Top },
  created() {
    this.getGoodsCateList();
  },
  data(){
    return {
      recommendCate: [],
      
    }
  },
  methods: {
    getGoodsCateList() {
      getGoodsCateList({
        app_id: this.$store.state.appId,
      }).then((res) => {
        console.log(res);
        this.recommendCate = res.data;
      });
    },
  },
};
</script>

<style lang="less">
.local_banner {
  img {
    width: 100%;
    height: 500px;
  }
}
.local_box {
  width: 1200px;
  margin: 30px auto;
}
.recommend_tit {
  padding-bottom: 25px;
}
</style>