<template>
  <div class="top_box">
    <div class="top_con">
      <div class="welcome_box">
        <div class="top_welcome">
          <div class="welcome_left">
            您好，欢迎来到安徽合肥幸福路消费帮扶公共服务平台
          </div>
          <div class="welcome_right">
            <div class="search">
              <form @submit.prevent="hanldeSearchGoods">
                <input type="text" placeholder="请输入商品名称搜索" v-model="search">
              <button @click="hanldeSearchGoods">搜索</button>
              </form>
            </div>
            <!-- <span style="cursor: pointer" @click.stop="showWx = true"
              >登录</span
            >
            <span>|</span> -->
            <span>指导单位：巢湖市商务局</span>
          </div>
          <div class="top_bg">
            <div class="top_round"></div>
          </div>
        </div>
      </div>
      <div class="logo_box">
        <img src="../assets/logo.png" />
      </div>
      <h3 class="top_tit">安徽合肥幸福路消费帮扶公共服务平台</h3>
      <ul class="nav_box">
        <router-link :to="{ name: 'Home' }">
          <li class="nav_list">
            <img src="../assets/icon_nav1.png" />
            <span>首页</span>
          </li>
        </router-link>
        <li
          class="nav_list"
          @mouseover="showClassify = true"
          @mouseleave="showClassify = false"
        >
          <img src="../assets/icon_nav2.png" />
          <span>商品分类</span>
        </li>
        <router-link :to="{ name: 'LocalLibrary' }">
          <li class="nav_list">
            <img src="../assets/icon_nav3.png" />
            <span>地方馆</span>
          </li>
        </router-link>
        <router-link :to="{ name: 'Supplier' }">
          <li class="nav_list">
            <img src="../assets/icon_nav4.png" />
            <span>供应商</span>
          </li>
        </router-link>
        <router-link :to="{ name: 'Beauty' }">
          <li class="nav_list">
            <img src="../assets/icon_nav5.png" />
            <span>合肥美景</span>
          </li>
        </router-link>
        <router-link :to="{ name: 'ContactUS' }">
          <li class="nav_list">
            <img src="../assets/icon_nav6.png" />
            <span>联系我们</span>
          </li>
        </router-link>
        <!--分类弹出层-->
        <div v-show="showClassify" class="classify_bg">
          <div
            @mouseover="showClassify = true"
            @mouseleave="showClassify = false"
          >
            <ul class="classify_box">
              <li
                class="classify_list"
                v-for="(item, index) in oneList"
                :key="index"
              >
                <span
                  style="cursor: pointer"
                  @click="navigateToGoodsList(item.one)"
                  >{{ item.one?.name }}</span
                >
                <img src="../assets/icon_arrow2.png" class="classify_arrow" />
                <div class="classify_con">
                  <span
                    v-for="(second, index) in item.secondList"
                    :key="index"
                    @click="navigateToGoodsList(second)"
                  >
                    {{ second.name }}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </ul>
    </div>

    <!--微信登录弹窗-->
    <div @click.stop="showWx = false">
      <div class="wx_bg" v-show="showWx">
        <login></login>
      </div>
    </div>
  </div>
</template>

<script>
import Login from "./Login.vue";
import { getClass } from "@/api/shop";
export default {
  name: "Top",
  component: {
    Login,
  },
  data() {
    return {
      showWx: false,
      showClassify: false,
      search: '',
      oneList: [
        // {
        //   one: "柑橘橙柚",
        //   secondList: ["橘子", "橙子", "红柚"],
        // },
        // {
        //   one: "柑橘橙柚",
        //   secondList: ["柑橘橙柚"],
        // },
        // {
        //   one: "柑橘橙柚",
        //   secondList: ["橘子", "橙子", "红柚"],
        // },
        // {
        //   one: "柑橘橙柚",
        //   secondList: ["橘子", "橙子", "红柚"],
        // },
      ],
    };
  },
  mounted() {
    this.search = this.$route.query.searchKey
    this.handlefetchClass();
  },
  methods: {
    hanldeSearchGoods(){
      if(!this.search){
        return
      }
      this.$router.push({
        name: "productList",
        query: {
          ...this.$route.query,
          searchKey: this.search,
        },
      });
    },
    navigateToGoodsList(item) {
      this.showClassify = false;
      this.$router.push({
        name: "productList",
        query: {
          cate_id: item.id,
        },
      });
    },
    handlefetchClass() {
      getClass({
        shop_id: this.$store.state.shopId,
        app_id: this.$store.state.appId,
      }).then((res) => {
        console.log(res);
        this.oneList = res.data.map((item) => {
          return {
            one: item,
            secondList: item.children,
          };
        });
      });
    },
    pushToChaohu() {
      this.$router.push({
        name: "Beauty",
      });
    },
  },
  components: { Login },
};
</script>

<style lang="less">
@import "../common/public.less";
.search{
  display: inline-block;
  margin-right: 20px;
  input{
    border: none;
    outline: none;
    padding: 7px 20px;
    border-radius: 100px;
    margin-right: 8px;
  }
  button{
    color: #FFF;
    cursor: pointer;
  }
}
.top_box {
  width: 100%;
  padding-bottom: 25px;
  .top_con {
    color: #fff;
    .welcome_box {
      height: 50px;
      background: @color;
      .top_welcome {
        width: 1200px;
        height: 50px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        position: relative;
        .welcome_right {
          text-align: right;
          span {
            &:nth-child(2) {
              margin: 0 15px;
            }
          }
        }
        .top_bg {
          position: absolute;
          left: 530px;
          top: -35px;
          overflow: hidden;
          width: 140px;
          .top_round {
            position: relative;
            width: 100%;
            height: 200px;
          }
          .top_round:after {
            width: 140%;
            height: 100px;
            position: absolute;
            left: -20%;
            top: 0;
            z-index: -1;
            content: "";
            border-radius: 0 0 50% 50%;
            background-color: @color;
          }
        }
      }
    }

    .logo_box {
      width: 100px;
      height: 100px;
      padding: 10px;
      background: #fff;
      border-radius: 50%;
      box-shadow: 0px 0px 7px #dedede;
      margin: -20px auto 0;
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
    }
    .top_tit {
      font-size: 40px;
      font-weight: bold;
      color: @color;
      text-align: center;
      padding: 30px 0;
    }
    .nav_box {
      width: 1100px;
      margin: 0 auto;
      position: relative;
      .disa;
      justify-content: space-around;
      .nav_list {
        font-size: 18px;
        color: @color;
        cursor: pointer;
        .disa;
        img {
          width: 25px;
          height: 25px;
          margin-right: 5px;
        }
      }
      .classify_bg {
        position: absolute;
        top: 48px;
        left: 0;
        z-index: 999999999;
        background: #fff;
        box-shadow: 0px 0px 7px #dcdcdc;
        width: 100%;
        .classify_box {
          padding: 30px;
          .classify_list {
            padding-bottom: 40px;
            font-size: 14px;
            color: #222;
            .dis;
            .classify_arrow {
              margin: 4px 20px 0 10px;
              width: 12px;
              height: 12px;
            }
            .classify_con {
              color: #555;
              .dis;
              span {
                margin-right: 20px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
.wx_bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
