import axios from 'axios';
import { ElMessage } from 'element-plus';
// import qs from 'qs'
const http = axios.create({
    // baseURL: 'http://cg.webliuxuegao.top', //配置后所有请求方法都会加上该路径
    timeout: 100000
})
//请求拦截器
http.interceptors.request.use(function (config) {
    config.headers = {
        ...config.headers,
    }
    console.log(config);
    // if (config.method == 'get') {
    //     var params={
    //         ...config.params
    //     }
    //     config.url = config.url + '?' + JSON.stringify(params);
    // }
    return config
}, function (error) {
    return Promise.reject(error) // 上个方法体内报错执行到此
})

http.interceptors.response.use(function (response) {
    if (response.data.code == 1) {
        return response.data
    }
    else {
        ElMessage.error(response.data.message)
        return Promise.reject(response)
    }
}, function (error) {
    return Promise.reject(error) // 上个方法体内报错执行到此
})

export default http