<template>
  <div>
    <top></top>
    <div style="width: 1200px; margin: 0 auto">
      <div class="nav_tit" style="padding-bottom: 0;">
        <span>首页</span>
        
        <template v-if="parent_cate?.id">
          <img src="../assets/icon_arrow1.png" />
          <router-link
            style="color: #555"
            :to="{ name: 'productList', query: { cate_id: parent_cate?.id } }"
          >
            <span>{{ parent_cate.name }}</span>
          </router-link>
        </template>
        <template v-if="cate?.id">
          <img src="../assets/icon_arrow1.png" />
          <router-link
            style="color: #555"
            :to="{
              name: 'productList',
              query: { cate_id: cate?.id },
            }"
          >
            <span>{{ cate?.name }}</span>
          </router-link>
        </template>
        <template v-if="$route.query.searchKey != undefind">
          <img src="../assets/icon_arrow1.png" />
          
            <span>商品搜索</span>
        </template>
      </div>
    </div>
    <div class="common_box">
      <div>
        <goods :goodsList="goodsList" v-if="goodsList.length"></goods>
        <el-empty v-else style="margin: 80px 0" />
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
        "
      >
        <el-pagination
          background
          :page-size="24"
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <bottom></bottom>
    <right></right>
  </div>
</template>

<script>
import Top from "../components/Top";
import Bottom from "../components/Bottom";
import Right from "../components/Right";
import Amplification from "../components/Amplification";
import Goods from "../components/Goods";

import { getGood } from "@/api/shop";
export default {
  name: "GoodsDetails",
  components: { Goods, Right, Bottom, Top, Amplification },
  data() {
    return {
      goodsList: [],
      total: 0,
      page: 1,
      parent_cate: {},
      cate: {}
    };
  },
  created() {
    this.page = this.$route.query.page || 1;
    this.handleFetchGoodsList();
  },
  watch: {
    ["$route.query.cate_id"]: function () {
      this.parent_cate = {}
      this.cate = {}
      this.page = 1
      this.handleFetchGoodsList();
      debugger
    },
    ['$route.query.searchKey']: function(){
      this.handleFetchGoodsList()
    },
    current() {
      this.productNumber = 1;
    },
  },
  methods: {
    backTop() {
      const timer = setInterval(function () {
        let backTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        let speedTop = backTop / 5;
        document.documentElement.scrollTop = backTop - speedTop;
        if (backTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
    handleCurrentChange(current) {
      this.backTop();
      this.page = current;

      this.handleFetchGoodsList();
      this.$router.replace({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          page: this.page,
        },
      });
    },
    handleFetchGoodsList() {
      const loading = this.$loading({
        lock: true,
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.3)",
      });
      getGood({
        cate_id: this.$route.query.cate_id,
        page_idx: this.page,
        page_size: 24,
        app_id: this.$store.state.appId,
        search_txt: this.$route.query.searchKey
      })
        .then((res) => {
          console.log(res);
          this.goodsList = res.data;
          this.total = res.datas.count;
          this.cate = res.datas.cate;
          this.parent_cate = res.datas.parent_cate;
        })
        .finally(() => {
          loading.close();
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../common/public.less";
/deep/.goods_list:nth-child(3n) {
  margin-right: 12.3px !important;
}
/deep/.goods_list:nth-child(4n) {
  margin-right: 0px !important;
}
/deep/.bot_z {
  padding: 50px 0 20px !important;
}

body {
  background: #fff;
}
.nav_tit {
  display: flex;
  align-items: center;
  padding: 20px 0;
  font-size: 14px;
  color: #666;
  img {
    margin: 0 5px;
  }
}
.particulars_box {
  .disf;
  .disf;
  .particulars_fr {
    width: 700px;
    padding: 20px 0;
    .particulars_tit {
      font-size: 20px;
      font-weight: bold;
    }
    .particulars_info {
      color: #666;
      padding: 10px 0 20px;
    }
    .particulars_core {
      background: #f1f1f1;
      border-radius: 10px;
      padding: 30px 10px 0;
      .core_list {
        padding-bottom: 20px;
        .core_label {
          display: inline-block;
          width: 50px;
          color: #777;
        }
        .core_price {
          color: @color;
          font-size: 24px;
          font-weight: bold;
        }
      }
      .core_list1 {
        border-top: 1px dashed #dbdbdb;
        padding-top: 20px;
      }
    }
    .specification_box {
      .disa;
      padding: 20px 0 20px;
      color: #777;
      .specification_tit {
        display: inline-block;
        width: 60px;
      }
      .specification_data {
        max-width: 640px;
        span {
          padding: 8px 30px;
          border: 1px solid #d7d7d7;
          margin-right: 30px;
          margin-top: 20px;
          display: inline-block;
        }
      }
      .number_box {
        border: 1px solid #d7d7d7;
        height: 40px;
        line-height: 40px;
        .number_operate {
          padding: 0 20px;
          font-size: 18px;
          cursor: pointer;
          user-select: none;
        }
        .number_data {
          width: 70px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: none;
          border: none;
          border-left: 1px solid #d7d7d7;
          border-right: 1px solid #d7d7d7;
        }
      }
    }
    .particulars_btn {
      width: 500px;
      height: 60px;
      line-height: 60px;
      background: @color;
      color: #fff;
      margin: 20px 100px;
    }
  }
}
.description_box {
  height: fit-content;
  align-items: stretch;
  margin-bottom: 200px;
  .disf;
  .description_fl {
    width: 830px;
    .description_tab {
      .disa;
      height: 65px;
      background: #f5f4f4;
      border: 1px solid #dcdcdc;
      padding-left: 1px;
      li {
        cursor: pointer;
        width: 150px;
        text-align: center;
        height: 65px;
        line-height: 65px;
      }
      .active {
        background: #fff;
        border-top: 2px solid @color;
      }
    }
    .description_synopsis {
      width: 100%;
      .synopsis_con {
        // font-size: 0;
        padding-top: 25px;
        img {
          width: 100%;
        }
      }
    }
    .evaluation_box {
      padding: 0 30px;
      .evaluation_list {
        .dis;
        border-bottom: 1px dashed #ddd;
        padding: 40px 0 20px;
        &:last-child {
          border-bottom: none;
        }
        .evaluation_fl {
          width: 70px;
          text-align: center;
          img {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            margin-bottom: 10px;
          }
        }
        .evaluation_fr {
          max-width: 720px;
          margin-left: 30px;
          .evaluation_time {
            font-size: 14px;
            color: #999;
            padding-bottom: 10px;
          }
          .evaluation_evimg {
            .dis;
            padding: 20px 0 0;
            img {
              width: 120px;
              height: 120px;
              border: 1px solid #ececec;
              margin-right: 20px;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
  .description_fr {
    width: 330px;
    border: 1px solid #dcdcdc;
    background: #fff;
    .hot_tit {
      background: #f5f4f4;
      padding: 20px 30px;
      text-align: center;
      font-size: 18px;
    }
    .hot_goods {
      margin: 0 20px;
    }
  }
}
</style>
