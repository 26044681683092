<template>
  <div>
    <div class="bg_box">
      <img :src="detail.pc_background || detail.background" style="height: 550px;object-fit: cover;"/>
      <span class="bg_right">5G慢直播</span>
    </div>
    <div class="details_box">
      <div class="big_video">
        <div class="video" ref="player"></div>
      </div>
      <div class="more_box">
        <p class="more_tit">更多直播</p>
        <ul class="more_con">
          <router-link
            :to="{ name: 'BeautyDetails', params: { id: item.id } }"
            :key="index"
            v-for="(item, index) in beautyList"
            class="item"
          >
            <li class="more_list">
              <!--            <div class="video_box">-->
              <!--              <video src="../assets/1.mp4" class="more_img"></video>-->
              <!--              <img src="../assets/icon_play.png" class="video_play">-->
              <!--            </div>-->
              <!-- <video src="../assets/1.mp4" class="more_img" controls></video>- -->
              <img :src="item.cover" style="width: 100%; height: 434px" />
              <div class="more_tit">{{ item.title }}</div>
              <div class="more_info">{{ item.title }}</div>
            </li>
          </router-link>
        </ul>
      </div>
    </div>
    <bottom></bottom>
    <right :showPrevious="true"></right>
  </div>
</template>

<script>
import Bottom from "../components/Bottom";
import Right from "../components/Right";
import Hls from "hls.js";
import Dplayer from "dplayer";
import { getLive, getLiveDetail } from "@/api/lives";
export default {
  name: "BeautyDetails",
  components: { Right, Bottom },
  data() {
    return {
      beautyList: [],
      title: "",
      dp: null,
      video: {},
      dpList: [],
      detail: {},
    };
  },
  mounted() {
    this.title = this.$route.params.title;
    this.getLiveDetail();
    this.getVideoList();
  },
  watch:{
    ['$route.params.id']: function(){
      this.getLiveDetail()
      this.getVideoList()
    } 
  },
  methods: {
    getLiveDetail() {
      getLiveDetail({
        id: this.$route.params.id,
      }).then((res) => {
        console.log(res);
        this.detail = res.data;

        this.video = {
          img: this.detail.cover,
          video: this.detail.url,
        };
        // 测试播放链接 https://wolongzywcdn2.com:65/fMl4SrDZ/index.m3u8
        this.loadVideo(this.video);
      });
    },
    loadVideo(videoInfo) {
      this.dp = new Dplayer({
        element: this.$refs.player,
        video: {
          pic: videoInfo.img, // 封面
          url: videoInfo.video,
          type: "customHls", //  customHls  mp4
          customType: {
            customHls: function (video, player) {
              console.log(player);
              const hls = new Hls();
              // 监听Hls.Events.ERROR事件，
              // DNS解析、下载超时，都会触发manifestLoadError错误
              hls.on(Hls.Events.ERROR, function (eventName, data) {
                // 埋点上报，可以追踪data.details
                // track()
                console.log(eventName, "------888---------", data);
              });
              hls.loadSource(video.src);
              hls.attachMedia(video);
            },
          },
        },
      });
    },

    getVideoList() {
      getLive({
        app_id: this.$store.state.appId,
      }).then((res) => {
        console.log(res);
        this.beautyList = res.data;

        this.dpList = [];
      });
    },
  },
};
</script>

<style lang="less">
@import "../common/public.less";
body {
  background: #f7f7f7;
}
.bg_box {
  width: 100%;
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
  .bg_right {
    position: absolute;
    right: 40px;
    top: 70px;
    display: inline-block;
    padding: 10px 30px;
    background: #1d80e9;
    color: #fff;
    border-radius: 40px;
    font-weight: bold;
  }
}
.item:nth-child(n + 1){
  margin-left: 15px
}
.details_box {
  width: 1200px;
  margin: -100px auto 0;
  position: relative;
  z-index: 9;
  .big_video {
    padding: 10px;
    background: #fff;
    border-radius: 10px;
    .video {
      width: 1180px;
      height: calc((1180px / 16) * 9);
    }
  }
  .dplayer-box {
    margin-top: 50px;
    padding: 10px;
    background: red;
    border-radius: 10px;
    height: 600px;
    .dplayer {
      width: 780px;
      height: 500px;
    }
  }
}

.more_box {
  padding: 30px 0;
  .more_tit {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 30px;
    color: #555;
  }
  .more_con {
    .dis;
    flex-wrap: wrap;
    .more_list {
      width: 580px;
      text-align: center;
      margin-bottom: 40px;
      background: #fff;
      padding-bottom: 20px;
      &:nth-child(2n) {
        margin-left: 40px;
      }
      .more_img {
        width: 580px;
        height: 300px;
      }
      .more_tit {
        font-size: 18px;
        padding: 15px 0;
        width: 200px;
        margin: 0 auto 15px;
        border-bottom: 1px solid #ececec;
        font-weight: bold;
      }
      .more_info {
        font-size: 14px;
        color: #999;
        padding: 0 150px;
        .overt;
      }
    }
  }
}
.video_box {
  position: relative;
  .disa;
  justify-content: center;
  height: 300px;
  video {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .video_play {
    width: 100px;
    height: 100px;
    position: relative;
    z-index: 5;
  }
}
</style>