<template>
  <div>
    <ul class="goods_box">
      <li v-for="(item,index) in goodsList" :key="index" class="goods_list" @click="goodsClick(item)">
        <img :src="item.goods_logo" class="goods_img">
        <div class="goods_name">{{item.goods_title}}</div>
        <div class="goods_price">
          <span class="goods_now">￥{{item.goods_min_price}}</span>
          <span class="goods_old">￥{{item.goods_min_price}}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Goods",
  props: {
    goodsList: {
      type: Array,
      default(){
        return []
      }
    }
  },
  data() {
    return {
      // goodsList:[
      //   {
      //     img:require("../assets/good_img.png"),
      //     name:"回味无穷好吃过瘾，油辣椒100克*2微辣",
      //     now:"19.9",
      //     old:"29.9",
      //   },
      //   {
      //     img:require("../assets/good_img.png"),
      //     name:"回味无穷好吃过瘾，油辣椒100克*2微辣",
      //     now:"19.9",
      //     old:"29.9",
      //   },
      //   {
      //     img:require("../assets/good_img.png"),
      //     name:"回味无穷好吃过瘾，油辣椒100克*2微辣",
      //     now:"19.9",
      //     old:"29.9",
      //   },{
      //     img:require("../assets/good_img.png"),
      //     name:"回味无穷好吃过瘾，油辣椒100克*2微辣",
      //     now:"19.9",
      //     old:"29.9",
      //   },{
      //     img:require("../assets/good_img.png"),
      //     name:"回味无穷好吃过瘾，油辣椒100克*2微辣",
      //     now:"19.9",
      //     old:"29.9",
      //   },



      // ],
    }
  },
  methods:{

    goodsClick(goods){
      this.$router.push({
        name:'GoodsDetails',
        params:{
          id:goods.id
        }
      })
    }
  }
};
</script>

<style lang="less">
 @import "../common/public.less";
 .goods_box{
   width: 100%;
   margin: 30px auto 0;
   .dis;
   flex-wrap: wrap;
   .goods_list{
     width: 290px;
     margin-right: 12.3px;
     text-align: center;
     margin-bottom: 20px;
     cursor: pointer;
     padding-bottom: 40px;
     &:nth-child(4n){
       margin-right: 0;
     }
     &:hover{
       background: #f4f4f4;
       box-shadow: 0px 0px 7px #d2d2d2;
     }
     .goods_img{
       width: 292px;
       height: 292px;
     }
     .goods_name{
       .overt;
       width: 250px;
       margin: 15px auto 10px;
     }
     .goods_price{
       .goods_now{
         color: @color;
         font-weight: bold;
       }
       .goods_old{
         font-size: 14px;
         color: #999;
         text-decoration:line-through;
         margin-left: 10px;
       }
     }
   }
 }

</style>