import http from '../utils/request'



/**
 * 获取商品详情
 * @param {*} params 
 * @returns 
 */
export function getGoodsDetail(params){
  return http.get('/pc/goods?m=getGoodsDetail',{params});
}


/**
 * 获取商品规格
 * @param {*} params 
 * @returns 
 */
export function getGoodsSpec(params){
  return http.get('/pc/goods?m=getSpecInfo',{params});
}

/**
 * 获取商品小程序页面二维码
 * @param {*} params 
 * @returns 
 */
export function getShareInfo(params){
  return http.get('/pc/goods?m=getShareInfo',{params});
}

/**
 * 获取评论列表
 * @param {*} params 
 * @returns 
 */
export function commonLists(params){
  return http.get('/pc/goods?m=commonLists',{params});
}

